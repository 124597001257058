import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/Layout"
import { Container } from "../components/Theme"

const WebsiteTermsOfUse = () => (
  <Layout>
    <Helmet
      title="Website Terms of Use - Metro Communications"
      meta={[
        {
          name: "description",
          content: `The text on this page (and any documents we refer to in it) sets out the terms and conditions for using our website, metrocomms.co.uk, and our other websites (microsites).`,
        },
        { name: "keywords", content: `` },
      ]}
    />
    <Container>
      <h1>Website Terms of Use</h1>
      <h3>1 Important information about using our website</h3>
      <p>
        The text on this page (and any documents we refer to in it) sets out the
        terms and conditions for using our website, metrocomms.co.uk, and our
        other websites (microsites).
      </p>
      <p>securecommunications.co.uk</p>
      <p>thehumanfirewall.me</p>
      <p>cyberburglaralarm.me</p>
      <p>
        Please read this information carefully before you start using our
        website(s).
      </p>
      <p>
        By using our website(s), you confirm that you accept these terms of use
        and agree to keep to them. If you do not agree to these terms, please
        stop using our website(s) immediately.
      </p>
      <h3>2 The law</h3>
      <p>
        Any disagreement under these terms and conditions will be decided by the
        courts of England and Wales.
      </p>
      <h3>3 About us</h3>
      <p>
        Our websites and microsites, listed above, are owned and managed by
        Metro Communications Limited.
      </p>
      <p>
        Metro Communications Ltd is a limited company registered in England and
        Wales (registration number 09830324). Our registered office is 128 City
        Road, London, EC1V 2NX.
      </p>
      <h3>4 Terms</h3>
      <p>
        In this document the words we, us and our mean Metro Communications
        Limited. The word you means you, the person using our website(s).
      </p>
      <h3>5 Accessing our website(s)</h3>
      <p>
        We allow you to have temporary access to our website(s). We may remove
        or change the services we provide on our website(s) in any way and
        without giving you notice. We are not legally responsible if our
        website(s) is not available for any reason.
      </p>
      <p>
        From time to time, we may limit access to some or all of our website(s).
      </p>
      <p>
        You must keep confidential any user identification codes, passwords or
        other information you use as part of our security procedures or to
        access our website(s). You must not share it with any other person or
        organisation.
      </p>
      <h3>6 Information about you and your visits to our website(s)</h3>
      <p>
        For details about what information we collect about you and your visits,
        how we collect it and how we use it, please read our privacy policy.
      </p>
      <h3>7 Relying on information posted</h3>
      <p>
        You should not rely on comments and other information that is posted on
        our website(s). Information posted on our website(s) may be out of date.
        We do not have to update this information and we may leave it on the
        website(s) – for example, to help customers who have bought products
        that may now be seen as old or obsolete. We aim to provide good-quality,
        up-to-date information and to be accurate at all times, but we cannot
        guarantee this. Because of this, we are not legally responsible for
        anything that happens as a result of you relying on materials available
        on or through our website(s).
      </p>
      <h3>8 Changes to our website(s)</h3>
      <p>
        We may regularly change the content and design of our website(s). If we
        need to, we may make our website(s) unavailable temporarily or for
        longer, without telling you when it will be available again.
      </p>
      <h3>9 Uploading information to our website(s)</h3>
      <p>
        We will not treat anything you upload to our website (such as
        screenshots or photos of faults) as confidential or owned by you. That
        means we may use it, copy it, and share it with other people and
        organisations for any purpose. We are not responsible for the content or
        accuracy of anything you upload to our website(s). If we believe that
        anything you have uploaded does not meet the standards set out in our
        acceptable use policy or we think it may affect another person’s or
        organisation’s intellectual property rights, we may remove it. If you
        upload anything to our website(s) that in our opinion could be criminal
        by nature then we will not hesitate to take the matter to the Police and
        support the authorities in bringing a case against you.
      </p>
      <h3>10 Links from our website(s)</h3>
      <p>
        Our website(s) includes links to other websites and resources provided
        by other organisations. These links are for your information only. We
        have no control over the content of the websites or resources linked to.
        We are not responsible for them or for any loss or damage that results
        from you using them. If one of our links is broken, please tell us so we
        can fix it.
      </p>
      <h3>11 Linking to our website(s)</h3>
      <p>
        You may link to our home page in a way that keeps to the law and doesn’t
        damage or take advantage of our reputation. Please do not link to our
        website(s) in a way that suggests that we approve of or endorse
        something, or that we are associated with it, if we have not agreed to
        this. To ask for our permission, please send an email to
        hello@metrocomms.co.uk and tell us about the link you want to create to
        our website(s). If we give our permission, we may withdraw it later
        without telling you first. If you create a link to our website(s),
        social media profiles or other online presence from a site that we do
        not want to receive links from, we will ask you to remove that link
        immediately. If you do not remove the link, we will take legal action
        against you.
      </p>
      <h3>12 Purchases</h3>
      <p>
        When you buy from us through our website(s), our terms and conditions of
        supply apply. These are available on request.
      </p>
      <h3>13 Acceptable use</h3>
      <p>
        Whenever you upload something to our website(s), including links, or
        communicate with other people using our website(s), you must keep to our
        acceptable use policy (available on request).
      </p>
      <h3>14 Viruses, hacking and other misuse</h3>
      <p>
        You must not misuse our website(s) or services. You must not try to gain
        access to our website(s) or any of our systems if you do not have our
        permission. You must not take part in any attack that aims to shut down
        our website(s) or make it unavailable to users (a denial-of-service
        attack). If you do not keep to this condition, you are breaking the law
        as set out in the Computer Misuse Act 1990. We will report this to the
        relevant authorities, co-operate fully with them and take legal action
        against you to get the highest level of compensation from you.
      </p>
      <p>
        If you break this condition, you will no longer have any of the rights
        set out in these terms of use but you must still keep to your legal
        responsibilities.
      </p>
      <h3>15 Intellectual property rights</h3>
      <p>
        We are the owner of or hold a licence for all the intellectual property
        rights that apply to our website and the information it contains. These
        rights are protected by copyright laws and treaties around the world and
        we may use them.
      </p>
      <p>
        You must always acknowledge that we (and any contributors we have named)
        are the authors of our website content. If you want to use any part of
        the content on our website(s) for business purposes, you must get our
        permission in writing first.
      </p>
      <p>
        Copyrights, registered trademarks and trade marks are identified by the
        use of the special characters ©, ® and ™. They are owned by us and other
        organisations. We acknowledge the ownership of others and make no claims
        whatsoever on the intellectual property of others.
      </p>
      <h3>16 Our legal responsibilities</h3>
      <p>
        We do not guarantee that the information published on our website(s) is
        accurate.
      </p>
      <p>
        As far as the law allows, we are not responsible for any loss or damage
        that you suffer because of using our website(s) or not being able to use
        our website(s), including any websites we have linked to and any
        materials or information posted on our website(s). This means we are not
        legally responsible for:
      </p>
      <ul>
        <li>loss of income or revenue;</li>
        <li>loss of business;</li>
        <li>loss of profits or contracts;</li>
        <li>loss of savings you expected to make;</li>
        <li>loss of data;</li>
        <li>loss of goodwill;</li>
        <li>wasted management or office time; or</li>
        <li>
          any other loss or damage, however it happens, even if it could have
          been predicted.
        </li>
      </ul>
      <p>
        However, you may claim for loss of, or damage to, your physical property
        and any direct financial losses that are not excluded above.
      </p>
      <h3>17 Changes to these terms</h3>
      <p>
        We update these terms of use from time to time. Please check this page
        when you visit our website(s) to make sure you keep up to date with any
        changes we have made.
      </p>
    </Container>
  </Layout>
)

export default WebsiteTermsOfUse
